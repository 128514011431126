import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import "../../Styles/Styles.css"
import "bootstrap/dist/css/bootstrap.min.css";


const containerStyle = {
    width: '100%',
    height: '50vh',
    // borderRadius:'10px',
    border: "2px solid #ddd"
};


// Custom map styles
const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ADD8E6"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  const mapOptions = {
    styles: mapStyles, // Apply styles here
    disableDefaultUI: true, // Optional: Hide default UI
    zoomControl: true, // Optional: Enable zoom control
  };

const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(price)
      : "N/A";

const Recommandedlocation = ({ proposal }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        // googleMapsApiKey: 'AIzaSyAtFGn5qR4tJYFfLt4DRrZQl2xjVZzjruM',
        googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
    });
    const locations = proposal.proposedOptions.map((option) => {
      const loc = option.location;
      return { lat: loc.lat, lng: loc.lng, centerName: option.centerName,locality:option.locality,billableSeats:option.billableSeats,pricePerSeat:formatPrice(option.pricePerSeat),availableFrom:option.availableFrom};
  });

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: Number(locations[0].lat), lng: Number(locations[0].lng) });
    const [zoom, setZoom] = useState(13);
    const [selectedMarker, setSelectedMarker] = useState(null);

    
    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.forEach((location) => {
            bounds.extend(new window.google.maps.LatLng(Number(location.lat), Number(location.lng)));
        });
        map.fitBounds(bounds);
        setMap(map);
    }, [locations]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleTouchStart = (event, rowId) => {
      console.log(`Touch started on row ${rowId}`);
      // Add your logic here
      // setZoom(13);
      // setCenter({ lat: Number(locations[rowId].lat), lng: Number(locations[rowId].lng) })
      map.panTo({ lat: Number(locations[rowId].lat), lng: Number(locations[rowId].lng) });
      // setZoom(16);
    };
    const handleTouchEnd = (event, rowId) => {
      // setZoom(13);
      // Add your logic here
    };

    return isLoaded ? (
        <div className="container mt-4">
          {/* Title */}
      <h1 className="display-4 text-start mb-4">
        Recommended Options
      </h1>
            <div className="row">
                {/* Map Section */}
                <div className="col-lg-6 col-md-12 mb-3 ">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        // mapTypeId="hybrid"
                        onUnmount={onUnmount}
                        options={mapOptions}
                    >
                        {locations.map((location, index) => (
                            <Marker
                                key={index}
                                position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                                onClick={() => setSelectedMarker(location)}
                                options={{ clickable: true }}
                                label={`${index + 1}`}
                        >
                        </Marker>
                            
                        ))}
                        {selectedMarker && (
                            <InfoWindow
                                key={`info-window-${selectedMarker.lat}-${selectedMarker.lng}`}
                                position={{ lat: Number(selectedMarker.lat), lng: Number(selectedMarker.lng) }}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <div>
                                    <h5>{selectedMarker.centerName}</h5>
                                    <p>{selectedMarker.locality}</p>
                                    <p>Price Per Seat : {selectedMarker.pricePerSeat}</p>
                                    <p>Billable Seats : {selectedMarker.billableSeats}</p>
                                    <p>Available From : {selectedMarker.availableFrom}</p>
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>

                {/* Table Section */}
                <div className="col-lg-6 col-md-12">
                    <div className="table-responsive">
                        <div className="table-container ">
                            <table className="location-table table-bordered" style={{ maxHeight: "600px", overflowY: "scroll" }}>
                                <tbody>
                                    {proposal?.proposedOptions?.map((item,index) => (
                                        <tr key={index} onTouchStart={(e) => handleTouchStart(e, index)} onClick={(e) => handleTouchStart(e,index)} onTouchEnd={(e) => handleTouchEnd(e, index)}>
                                            <td>{index + 1}</td>
                                            <td className='location-column p-3'>
                                            <a
                    href={`#${item.centerName}`}
                  >
                    {item.centerName || "N/A"}
                  </a>
                                                {/* {item.centerName} */}
                                                </td>
                                            <td className='p-3'>{item.location.address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    ) : (
        <div className="text-center">Loading...</div>
    );
};

export default Recommandedlocation;
