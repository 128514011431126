import React, { useEffect } from "react";
import Centerdetails from "../../Pages/Centerdetails";

const Centerdetailscard = ({proposal, proposalId, shortlistedCenters}) => {

  return (
    <div className="container">
      {proposal?.proposedOptions?.map((option, index) => {
        return (
          <Centerdetails
            key={index}
            proposalId={proposalId}
            proposal={proposal}
            option={option}
            centerName={option.centerName}
            inventoryDescription={option.inventoryDescription}
            billableSeats={option.billableSeats}
            pricePerSeat={option.pricePerSeat}
            totalRentPerMonth={option.totalRentPerMont}  
            amenities={option.amenities}
            termsAndConditions={option.terms}
            virtualTour={option.virtualTour}
            location={option.location}
            layouts={option.layout}
            photos={option.photos}
            availableFrom={option.availabilityDate}
            parkingCharges={option.parkingCharges}
            securityDeposit={option.securityDeposit}
            bikeParkingCost = {option.bikeParkingCost}
            carParkingCost = {option.carParkingCost}
            shortlistedCenters={shortlistedCenters}
          />
        );
      })}
    </div>
  );
};

export default Centerdetailscard;
