import React from "react";
import "../../Styles/Recommendedspaces.css";

const Recommendedspaces = ({ proposal }) => {
  const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(price)
      : "N/A";

  return (
    <div className="container mt-5" id="RecommendedSpaces">
      {/* Title */}
      {/* <h1 className="display-4 text-start mb-4">
        Recommended Options
      </h1> */}

      {/* Table Wrapper */}
      <div className="table-responsive  mb-5">
        <table className="scrollable-table table-bordered align-middle text-center">
          <thead className="header text-white">
            <tr>
              <th className="sticky-column" style={{ width: "150px"}}>Space Details</th>
              {proposal?.proposedOptions?.map((option, index) => (
                <th key={index}>
                  <a
                    href={`#${option.centerName}`}
                    className="fw-bold"
                  >
                    {option.centerName || "N/A"}
                  </a>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Price per Seat */}
            <tr>
              <td className="fw-bold sticky-column">Price per Seat</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" key={index}>{formatPrice(option.pricePerSeat)}</td>
              ))}
            </tr>
            {/* Billable Seats */}
            <tr>
              <td className="fw-bold sticky-column">Billable Seats</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" key={index}>{option.billableSeats}</td>
              ))}
            </tr>

            {/* Total Rent */}
            <tr>
              <td className="fw-bold sticky-column text-wrap">Total Rent per Month</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" key={index}>{formatPrice(option.totalRentPerMont)}</td>
              ))}
            </tr>

            {/* Location */}
            <tr>
              <td className="fw-bold sticky-column">Location</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" className="table-values" key={index}>{option.locality || "N/A"}</td>
              ))}
            </tr>

            {/* Security Deposit */}
            <tr>
              <td className="fw-bold sticky-column">Security Deposit</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" key={index}>{formatPrice(option.securityDeposit)}</td>
              ))}
            </tr>

            {/* Notice Period */}
            <tr>
              <td className="fw-bold sticky-column">Notice Period</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td key={index}>{option.noticePeriod || "N/A"} Month</td>
              ))}
            </tr>

            {/* Lockin Period */}
            <tr>
              <td className="fw-bold sticky-column">Lockin Period</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td key={index}>{option.lockinPeriod || "N/A"} Month</td>
              ))}
            </tr>

            {/* Parking Charges */}
            <tr>
              <td className="fw-bold sticky-column">Parking Charges</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td key={index} className="table-values text-wrap">
                  Car-{formatPrice(option.carParkingCost)} & Bike-
                  {formatPrice(option.bikeParkingCost)}
                </td>
              ))}
            </tr>

            {/* Available From */}
            <tr>
              <td className="fw-bold sticky-column">Available From</td>
              {proposal?.proposedOptions?.map((option, index) => (
                <td className="table-values" key={index}>{option.availableFrom || "N/A"}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <hr/>
    </div>
  );
};

export default Recommendedspaces;
