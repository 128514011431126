import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase/config';
import Screen from './Pages/Screen';
import Login from './Pages/Login';
import flyloader from "./assest/image/Fetching Proposal.gif";
import "./index.css";

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  console.log('App.js - Location:', location.search);
  console.log('App.js - ProposalId:', proposalId);

  return (
    <Routes>
      <Route path="/" element={<Login proposalId={proposalId} />} />
      <Route
        path="/proposal"
        element={
          <ProposalFetcher proposalId={proposalId} />
        }
      />
    </Routes>
  );
};

const ProposalFetcher = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shortlistedCenters, setShortlistedCenters] = useState({});
  const [schedules, setSchedules] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  useEffect(() => {
    const fetchProposalData = async () => {
      try {
        const authToken = window.localStorage.getItem('authToken');

        if (!authToken) {
          navigate('/?proposalId=' + proposalId);
          return;
        }

        // Check if proposal exists in Firestore
        const proposalRef = doc(db, 'proposals', proposalId);
        const proposalDoc = await getDoc(proposalRef);

        let proposalData;
        let shouldFetchFromAPI = false;

        if (!proposalDoc.exists()) {
          // Proposal not in database, must fetch from API
          shouldFetchFromAPI = true;
        } else {
          // Check if proposedOptions is empty or missing
          const existingProposalData = proposalDoc.data();
          if (!existingProposalData.proposedOptions || existingProposalData.proposedOptions.length === 0) {
            shouldFetchFromAPI = true;
          } else {
            proposalData = existingProposalData;
          }
        }

        // Fetch from API if needed
        if (shouldFetchFromAPI) {
          const baseUrl = 'https://gofloaters.firebaseapp.com';
          const response = await axios.get(`${baseUrl}/proposal/${proposalId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            }
          });

          if (response.data && response.data.data) {
            proposalData = response.data.data;

            // Store API response in Firestore
            await setDoc(proposalRef, {
              clientName: proposalData.clientName || "Unknown Client",
              requirements: proposalData.requirements || "No requirements specified",
              proposedOptions: proposalData.proposedOptions || [],
              refreshData: !proposalData.proposedOptions || proposalData.proposedOptions.length === 0,
              lastUpdated: new Date().toISOString()
            }, { merge: true });
          } else {
            throw new Error('Invalid response format from server');
          }
        }

        // Ensure proposalData is set
        if (!proposalData) {
          throw new Error('No proposal data available');
        }

        setProposal(proposalData);
      } catch (error) {
        console.error('Error fetching proposal:', error);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            // Authentication error - redirect to login
            navigate('/?proposalId=' + proposalId);
            return;
          } else if (error.response.status === 500) {
            setError('Server error. Please try again later.');
          } else if (error.response.status === 404) {
            setError('Proposal not found. Please check the proposal ID.');
          } else {
            setError(error.response.data?.message || 'Error fetching proposal data');
          }
        } else if (error.request) {
          setError('No response from server. Please check your internet connection.');
        } else {
          setError('Error fetching proposal data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (proposalId) {
      fetchProposalData();

      // Add onSnapshot listener for proposal and shortlist updates
      const proposalRef = doc(db, 'proposals', proposalId);
      const unsubscribe = onSnapshot(proposalRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          
          // Update proposal data if it exists
          if (data.proposedOptions) {
            setProposal(prevProposal => ({
              ...prevProposal,
              ...data
            }));
          }

          // Update shortlisted centers
          const shortlistData = data.shortlist || [];
          const shortlistMap = shortlistData.reduce((acc, item) => {
            acc[item.inventoryId] = item.shortlistedBy || [];
            return acc;
          }, {});
          setShortlistedCenters(shortlistMap);

          // Update visit schedules
          setSchedules(data.visitSchedule || []);
        }
      }, (error) => {
        console.error('Error listening to proposal changes:', error);
      });

      return () => unsubscribe();
    } else {
      setError('No proposal ID provided');
      setLoading(false);
    }
  }, [proposalId, navigate]);

  if (loading) return (
    <div className='loader-head'>
      <img className='loadernew' src={flyloader} alt="Loading..." />
    </div>
  );

  if (error) return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <p style={{ fontSize: 20, fontWeight: 600, color: '#dc3545' }}>{error}</p>
      <p style={{ fontSize: 16, color: '#666', marginTop: '1rem' }}>
        If the problem persists, please contact support.
      </p>
    </div>
  );

  if (!proposal) return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <p style={{ fontSize: 20, fontWeight: 600, color: '#666' }}>
        Unable to retrieve proposal details. Please try again later.
      </p>
    </div>
  );

  return <Screen 
    proposal={proposal} 
    proposalId={proposalId} 
    shortlistedCenters={shortlistedCenters}
    schedules={schedules}
  />;
};

export default App;
