import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  return (
    <div
      className={`modal fade ${isOpen ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="imageModalLabel"
      aria-hidden={!isOpen}
      style={{ backgroundColor: isOpen ? "rgba(0, 0, 0, 0.5)" : "transparent" }} // Overlay effect
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content " style={{ backgroundColor: "transparent", border: "none" }}>
          <div className="modal-header d-flex justify-content-end"
            style={{ border: "none" }}
          >
            <button
              type=""
              className="mt-5"
              onClick={onClose}
              aria-label="Close"
              style={{
                backgroundColor: "transparent",
                color: "white", // White text for the "×" symbol
                border: "none", // Remove default border
                fontSize: "40px", // Size for the "×" symbol
              }}
            >
              <span aria-hidden="true" style={{
                backgroundColor: "transparent",}}>&times;</span>
            </button>

          </div>
          <div className="modal-body h-50 text-center">
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Preview"
                style={{ maxWidth: "100%", height: "100%" }}
              />
            ) : (
              <p>No image provided</p>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default ImageModal;
