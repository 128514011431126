import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { db, auth } from '../../firebase/config';
import { doc, updateDoc, deleteField, Timestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Generate time slots from 9 AM to 5 PM with 30-minute intervals
const generateTimeSlots = () => {
    const slots = [];
    const start = 9; // 9 AM
    const end = 17; // 5 PM

    for (let hour = start; hour <= end; hour++) {
        for (let minute of ['00', '30']) {
            if (hour === end && minute === '30') continue; // Skip 5:30 PM
            const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
            const hour12 = hour > 12 ? hour - 12 : hour;
            const period = hour >= 12 ? 'PM' : 'AM';
            const time12 = `${hour12}:${minute} ${period}`;
            slots.push({ value: time24, label: time12 });
        }
    }
    return slots;
};

const TIME_SLOTS = generateTimeSlots();

const ScheduleModal = ({ show, handleClose, centerId, proposalId, currentSchedule }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleSchedule = async () => {
        if (!auth.currentUser || !selectedDate || !selectedTime) return;

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            
            // Create timestamp for scheduledAt (current time)
            const scheduledAt = Timestamp.now();
            
            // Create timestamp for scheduledFor (selected date and time)
            const [hours, minutes] = selectedTime.split(':');
            const scheduledForDate = new Date(selectedDate);
            scheduledForDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
            const scheduledFor = Timestamp.fromDate(scheduledForDate);

            const scheduleData = {
                inventoryId: centerId,
                scheduledAt: scheduledAt,
                scheduledBy: auth.currentUser.email,
                scheduledFor: scheduledFor
            };

            // Get existing visitSchedule array or create new one
            const existingSchedules = currentSchedule?.visitSchedule || [];
            const updatedSchedules = [...existingSchedules];

            // Find and update existing schedule for this inventory if it exists
            const existingIndex = updatedSchedules.findIndex(
                schedule => schedule.inventoryId === centerId
            );

            if (existingIndex !== -1) {
                updatedSchedules[existingIndex] = scheduleData;
            } else {
                updatedSchedules.push(scheduleData);
            }

            await updateDoc(proposalRef, {
                visitSchedule: updatedSchedules
            });

            handleClose();
        } catch (error) {
            console.error('Error scheduling visit:', error);
        }
    };

    const handleDelete = async () => {
        if (!auth.currentUser) return;

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            
            // Filter out the schedule for this centerId
            const updatedSchedules = currentSchedule?.visitSchedule?.filter(
                schedule => schedule.inventoryId !== centerId
            ) || [];

            await updateDoc(proposalRef, {
                visitSchedule: updatedSchedules
            });

            setShowDeleteConfirm(false);
            handleClose();
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    // Find current schedule for this inventory
    const currentInventorySchedule = currentSchedule?.visitSchedule?.find(
        schedule => schedule.inventoryId === centerId
    );

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule Visit</Modal.Title>
                    {currentInventorySchedule && (
                        <Button 
                            variant="outline-danger" 
                            size="sm"
                            className="ms-3"
                            onClick={() => setShowDeleteConfirm(true)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete Schedule
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Date*</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        min={new Date().toISOString().split('T')[0]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Time*</Form.Label>
                                    <Form.Select
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    >
                                        <option value="">Select a time</option>
                                        {TIME_SLOTS.map((timeSlot, i) => (
                                            <option key={i} value={timeSlot.value}>
                                                {timeSlot.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {currentInventorySchedule && (
                            <div className="current-schedule p-3 bg-light rounded">
                                <h6 className="mb-2">Scheduled For:</h6>
                                <p className="mb-1">
                                    Date: {new Date(currentInventorySchedule.scheduledFor.seconds * 1000).toLocaleDateString()}
                                </p>
                                <p className="mb-1">
                                    Time: {new Date(currentInventorySchedule.scheduledFor.seconds * 1000).toLocaleTimeString([], { 
                                        hour: '2-digit', 
                                        minute: '2-digit'
                                    })}
                                </p>
                                <p className="mb-0 text-muted">
                                    Scheduled by: {currentInventorySchedule.scheduledBy}
                                </p>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Back
                    </Button>
                    <Button 
                        variant="success" 
                        onClick={handleSchedule}
                        disabled={!selectedDate || !selectedTime}
                    >
                        Schedule
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this schedule?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScheduleModal;
