import React from "react";
import Gofloaters from "../../assest/image/header.png"
import Logo from "../../assest/image/CBRE_green.png"
import ClientLogo from "../../assest/image/accenture-logo.png"
import "../../Styles/OfficeProposal.css"

export default function OfficeProposal({ proposal }) {
    const requirementsList = proposal?.requirements.split('\n');

    return (
        <div className="container office-proposal-container">
            <div className="row align-items-center">
                {/* Left Section */}
                <div className="col-12 col-md-6 text-start">
                    <img
                        src={'https://assets.gofloaters.com/logo.png'}
                        alt="GoFloaters Logo"
                        className="text-start"
                        style={{ maxWidth: 200, height: "auto" }}
                    /> 
                    <div className="text-start mt-4">
                        <p className="display-5" style={{ marginTop: "30px" }}>Flexible Office </p>
                        <p className="display-5">Proposal {proposal.clientName}</p>
                        {/* <img
                        src={ClientLogo}
                        alt="GoFloaters Logo"
                        className="text-start my-4"
                        style={{ maxWidth: 150, height: "auto" }} */}
                    {/* /> */}
                        <div className="requirement-head mt-4">
                            <h3 className="text-start mx-3">Requirements :</h3>
                            <ul>
                                {requirementsList.map((requirement, index) => (
                                    <li key={index} className="text-start requirement-li mx-3">{requirement}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <p>26-Nov-2024</p>
                </div>
                {/* Right Section */}
                <div className="col-12 col-md-6">
                    <img
                        src={Gofloaters}
                        alt="Office Space"
                        className="rounded img-fluid align-item-start mb-5"
                    />
                </div>
            </div>
            <hr/>
        </div>
    )
}
