import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID
};

// Validate environment variables
const requiredEnvVars = [
  'REACT_APP_PROD_FIREBASE_API_KEY',
  'REACT_APP_PROD_FIREBASE_AUTH_DOMAIN',
  'REACT_APP_PROD_FIREBASE_PROJECT_ID',
  'REACT_APP_PROD_FIREBASE_STORAGE_BUCKET',
  'REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID',
  'REACT_APP_PROD_FIREBASE_APP_ID',
  'REACT_APP_PROD_FIREBASE_MEASUREMENT_ID'
];

const missingEnvVars = requiredEnvVars.filter(varName => !process.env[varName]);
if (missingEnvVars.length > 0) {
  throw new Error(`Missing required environment variables: ${missingEnvVars.join(', ')}`);
}

console.log("Firebase config loaded successfully");

const app = initializeApp(firebaseConfigProd);

export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
